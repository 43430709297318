<script>
import Logo from "./Logo.svelte";
import Socials from "./Socials.svelte";
import Tagline from "./Tagline.svelte";

</script>
<style lang="scss">   

    .wrapper {
        width: 100%;
        padding-top: 1.3vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;

        @media (max-width: 768px) {
            padding-top: 5.6vw;
        }
    }
    .background {
        position: absolute;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 65.3vw;
        background: transparent linear-gradient(180deg, #000000 0%, #00000045 87%, #160B7100 100%) 0% 0% no-repeat padding-box;
        opacity: 0.85;
        @media (max-width: 768px) {
            height: 62vw;
        }
    }
    
    .logo-zenika {
        font-size: 1.25vw;
        color: #FFFFFF;
        text-shadow: 0px 3px 6px #2D2DCBBF;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: .5vw;
        
        p:first-child {
            margin-bottom: 1.1vw;
        }
        
        
        img {
            width: 16.56vw;
            height: 3.75vw;
            margin-bottom: 0.25vw;
        }
        
        @media (max-width: 768px) {
            font-size: 3.63vw;
            margin-bottom: 10.5vw;
            
            p:first-child {
                margin-bottom: 2.5vw;
            }
            
            img {
                width: 30.1vw;
                height: 6.8vw;
            }
            
            & p:last-child {
                font-size: 1.6vw;
            }
        }
    }

    .socials {
        position: absolute;
        top: 3.5vw;
        left: 4.7vw;

        @media (max-width: 768px) {
            display: none;
        }
    }
</style>

<div class="wrapper">
    <div class="background"/>
    <div class="socials">
        <Socials color="purple"/>
    </div>
    <div class="logo-zenika">
        <p>Animé par la passion</p>
        <img src="assets/logo-zenika-title.svg" alt="logo zenika" />
        <p>présente</p>
    </div>
    <Logo/>
    <Tagline/>
</div>
