<script>
    export let title, link;
    const onClick = () => {
        window.open(link, "_blank");
    }
</script>

<style lang="scss">
button {
    background: transparent linear-gradient(69deg, #B51432 0%, #160B72 100%) 0% 0% no-repeat padding-box;
    border: transparent;
    border-radius: 1.04vw;
    color: white;
    font-size: 1.9vw;
    line-height: 1.5vw;
    padding: 0.8vw 1.3vw;
    font-weight: 600;
    height: 5.2vw;
    cursor: pointer;
    min-width: 20.1vw;
    @media (max-width: 768px) {
        padding: 2.7vw;
        font-size: 3.6vw;
        height: 9.7vw;   
        min-width: 37vw;
        border-radius: 4.5vw;
    }

    div {
        background-color: transparent;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    &:hover {
        background: #B51432;
    }
    
    &:active {
        background: white;
        div {
            background: transparent linear-gradient(69deg, #B51432 0%, #160B72 100%) 0% 0% no-repeat padding-box;
            background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
</style>

<button type="button" role="button" on:click={onClick}>
    <div>
        {title}
    </div>
</button>
