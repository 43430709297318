<style lang="scss">
    p {
        line-height: 4.16vw;
        @media (max-width: 768px) {
            line-height: 9vw;
        }
    }

    .wrapper {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 12vw;
        text-align: center;
    }

    p:first-child {
        font-size: 4.8vw;
        line-height: 5.8vw;   
        font-weight: bold;
        @media (max-width: 768px) {
            font-size: 11.3vw;
            margin-bottom: 12vw;
        }
    }
    
    p.subtitle {
        font-size: 2.9vw; 
        font-weight: bold;
        &:after {
            content: ":";
            margin-left: .7vw;
        }
        @media (max-width: 768px) {
            font-size: 7.5vw;
            margin-bottom:  7vw;
            &:after {
                content: "";
            }
        }
    }

    p:nth-child(3) {
        font-size: 2.9vw; 
        @media (max-width: 768px) {
            font-size: 4.5vw;
            line-height: 5.4vw;
        }
    }

    p:nth-child(4) {
        font-size: 2.9vw; 
        margin-bottom: 2.86vw;
        @media (max-width: 768px) {
            font-size: 4.5vw;
            line-height: 5.4vw;
        }
    }
    
    .subtext:last-child {
        margin-bottom: 0px;        
    }

    .subtext {
        font-size: 2.08vw; 
        @media (max-width: 768px) {
            font-size: 4.5vw;
            line-height: 5.4vw;
        }
    }

</style>

<div class="wrapper">
    <p>La NEXT</p>
    <p class="subtitle">une conférence centrée autour du bon produit</p>
    <p>quand innovation produits et services riment</p>
    <p>avec performance technologique.</p>
    <p class="subtext">La NEXT est un événement hybride avec une formule en présentiel</p>
    <p class="subtext">(si la situation sanitaire le permet) ou en remote pour ceux qui le souhaitent.</p>
</div>
