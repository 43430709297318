<script>
    import Title from "./Title.svelte";
    import Button from "./Button.svelte";

    const buttonTitle = "Participer au CFP"
    const buttonLink ="https://conference-hall.io/public/event/cDyveI0ZeD8OtAjQLZ1n"
</script>

<style lang="scss">
    .wrapper {
        margin-bottom: 4.9vw;
        position: relative;
    }

    .image-wrapper {
        position: relative;
        width: 45vw;
        height: 36.1vw;
        background-color: pink;
        
        @media (max-width: 768px) {
            margin-left: 7.7vw;
            width: 74vw;  
            height: 47.4vw;
        }
   
        .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center top;
        }
        .filter {
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .content {
        display: flex ;
        margin-top: 1.5vw;
        @media (max-width: 768px) {
            flex-direction: column-reverse;
        }
    }
    .description {
        width: 36.5vw;
        margin-left: 7.7vw;
        line-height: 2.6vw;
        font-size: 2.1vw;
        text-align: center;
        margin-bottom: 4.1vw;
        @media (max-width: 768px) {
            margin: 0;
            padding-left: 10.9vw;
            width: 89%;
            text-align: left;
            font-weight: normal;
            font-size:  4.5vw;
            line-height: 5.4vw;
            padding-right: 5vw;
            margin-bottom: 27vw;
        }
    }
    .description > p:first-child {
        font-size:  2.1vw;
        line-height: 2.6vw;
        font-weight: bold;
        margin-bottom: 2.6vw;
        @media (max-width: 768px) {
            font-weight: normal;
            font-size:  4.5vw;
            line-height: 5.4vw;
        }
        
    }
    .description > p:nth-child(2) {
        font-weight: medium;
        @media (max-width: 768px) {
            font-weight: bold;
        }
    }
    .description > p:nth-child(2) > span {
        font-weight: bold;   
    }
    
    .description > p:nth-child(3){
        @media (max-width: 768px) {
            font-weight: bold;   
            
        }
    }

    .description >  p:nth-child(4){
        font-weight: medium;
        margin-bottom:2.6vw;
        font-size: 1.5vw;
        @media (max-width: 768px) {
            font-weight: normal;
            font-size:  4.5vw;
            line-height: 5.4vw;
        }
        
    }
    
    p.detail {
        font-size: 1.3vw;
        margin-left: 7.7vw;
        line-height: 2.6vw;        
        font-weight: 100;
        @media (max-width: 768px) {
            font-size: 2.7vw;
            margin: 4vw 0 6vw 0;
            margin-left: 10.9vw;
        }
    } 
    
    .grapik {
        z-index:-1;
        position: absolute;
        left: -7vw;
        width: 79.2vw;
        height: 40.1vw;
        top:-7vw;
        @media (max-width: 768px) {
            transform-origin: 25%;
            transform: scale(1.7, 1.3);
            left: 0;
            top:-4vw;
        }
    }

    .button {
        @media (max-width: 768px) {
            align-self: flex-end;
            margin-right: 11.8vw;
        }
    }
    .fullsize {
        @media (max-width: 768px) {
            display: none;
        }
    }
    .mobile {
        display: none;
        @media (max-width: 768px) {
            display: block;
        }
    }
    .accent {
        position: absolute;
        left: 5.6vw;
        height: 71vw;
    }
</style>

<div class="wrapper">
    <img class="accent mobile" src="assets/call-for-paper-accent.svg" alt="accent"/>
    <Title title="Call for paper" subTitle="Faites partie de notre programme en soumettant un talk"/>

    
    <div class="content">
        <div class="button mobile">
            <Button title={buttonTitle} link={buttonLink}/>
        </div>
        <p class="detail mobile">La sélection se fera fin Septembre par les équipes de Zenika</p>

        <div class="image-wrapper">
            <img class="image" src='assets/conf.png' alt='speaker à une conférence'/>
            <img class="filter" src="assets/conf-filter.svg" alt="filtre"/>
            <img class="grapik" src="assets/grapik_1.svg" alt="forme gemetrique"/>
        </div>
        <div>
            <div class="description">
                <p>Chaque talk aura sa place à la NEXT.</p>
                <p><span>Retour d’expériences et talks autour</span> du produit et du craftsmanship</p>
                <p>avec des durées variables</p>
                <p>(20 à 45 minutes)</p>
                <div class="fullsize">
                    <Button title={buttonTitle} link={buttonLink}/>
                </div>
            </div>
            <p class="detail fullsize">La sélection se fera fin Septembre par les équipes de Zenika</p>
        </div>
    </div>
</div>
