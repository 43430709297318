<script>
    import CallForPaper from "./components/CallForPaper.svelte";
    import Header from "./components/Header.svelte";
    import Inscription from "./components/Inscription.svelte";
    import Presentation from "./components/Presentation.svelte";
    import PreviousEdition from "./components/PreviousEdition.svelte";
    import Footer from "./components/Footer.svelte";
</script>


<svelte:head>
  <title>Next 2021</title>
  <link rel="preconnect" href="https://fonts.googleapis.com">
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">
</svelte:head>


<Header/>
<Presentation/>
<CallForPaper/>
<Inscription/>
<PreviousEdition/>
<Footer/>