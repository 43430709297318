<script>
    import Title from "./Title.svelte";
    import Button from "./Button.svelte";

    const buttonTitle = "Prendre votre billet"
    const buttonLink ="https://www.eventbrite.fr/e/billets-next-2022-produit-et-craftsmanship-167589232907"
</script>

<style lang="scss">
 .fullsize {
        @media (max-width: 768px) {
            display: none;
        }
    }
    .mobile {
        display: none;
        @media (max-width: 768px) {
            display: block;
        }
    }
    
    .wrapper {
        margin-bottom: 10.3vw;
        position: relative;
    }
    
    .content {
        display: flex ;
        margin-top: 3vw;
        position: relative;
        overflow-x: clip;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    
    .image {
        position: relative;
        width: 100%;
        @media (max-width: 768px) {
            display: flex;
            justify-content: flex-end;
        }
    }

    .description {
        margin-top: 1.4vw;
        text-align: right;
        width: 47.5vw;
        line-height: 2.6vw;
        font-size: 2.14vw;
        & span {
            font-weight: bold;
        }
        & > div{
            margin-bottom: 2.6vw;
            
        }
        & > p {
            margin-bottom: 2.6vw;        
        }
        
        @media (max-width: 768px) {
            width: 100%;
            text-align: left;
            margin-left: 10.9vw;
            font-size: 4.5vw;
            line-height: 5.4vw;
            width: 80%;
            
            & > div{
                margin-bottom: 5.4vw;
            }

            & > p{
                margin-bottom: 5.4vw;
            }
        }
    }
   

    
    p.detail {
        text-align: right;
        font-size: 1.04vw;
        line-height: 1.2vw;
        margin-top: 2.7vw;
        @media (max-width: 768px) {
            font-size: 2.7vw;
            line-height: 3.4vw;
            margin: 0 4vw  7vw 0;
        }
    } 
    .grapik-3 {
        z-index:-1;
        position: absolute;
        right:-44.5vw;
        top:-9.7vw;
        width: 73.7vw;
        height: 70.9vw;
    }

    .grapik-4 {
        @media (max-width: 768px) {
            z-index:-1;
            position: absolute;
            left: 22.7vw;
            top: 12vw;
        }
    }
    
    .grapik-2 {
        z-index:-1;
        position: absolute;
        top: -7.3vw;
        left: -3.6vw;
        width: 57.8vw;
        height: 56vw;
        @media (max-width: 768px) {
            left: -6vw;
            top: 49vw;
        }
    }
    
    .audiance {
        width: 43.4vw;
        height: 39.9vw;
        object-fit: cover;
        object-position: left top;
        position: absolute;
        right: 0;
        @media (max-width: 768px) {
            position: relative;
            width: 76.6vw;
            height: 58.5vw;
        }
    }

    .call-to-action {
        z-index: 2;
        right: 3.2vw;
        top: 5%;
        position: absolute;
        font-size: 4.9vw;
        font-weight: 600;
        color: #C6ACB2;
        opacity: 0.74;
        letter-spacing: 0.21vw;
        @supports (-webkit-text-stroke: .2vw #C6ACB2) {
    
                -webkit-text-stroke: .2vw #C6ACB2;
                -webkit-text-fill-color: transparent;
    
        }
        @media (max-width: 768px) {
            font-size: 8.8vw;
        }
    }
    .button {
        @media (max-width: 768px) {
            display: flex;
            justify-content: flex-end;
            padding-right: 10.6vw;
        }
    }

    .accent {
        position: absolute;
        left: 5.6vw;
        height: 95vw;
    }
    
</style>

<div class="wrapper">    
    <img class="accent mobile" src="assets/inscrivez-vous-accent.svg" alt="accent"/>
    <Title title="Inscrivez-vous !" subTitle="Venez vivre une nouvelle expérience sociale remote"/>
    <div class="content">
        <div>
            <div class="description">
                <div>
                    <p><span>En apprendre</span> plus sur</p>
                    <p>le Product & le Craftsmanship</p>
                </div>
                <p><span>Rencontrer vos</span> pairs</p>
                <div>
                    <p><span>Partager</span> et interagir avec</p>
                    <p>les intervenants à tout moment</p>
                </div>
                <div>
                    <p><span>Collaborer</span> avec de vrais temps</p>
                    <p>d’échanges</p>
                </div>
                <div class="fullsize">
                    <Button title={buttonTitle} link={buttonLink}/>
                </div>
            </div>
            <p class="detail fullsize">Les places étant limitées, l’inscription est obligatoire</p>
        </div>
        <div/>
        
        <div class=image>
            <img class="audiance" src="assets/inscription.png" alt="inscrivez vous !"/>
            <p class=call-to-action>inscrivez-vous</p>
        </div>
        <p class="detail mobile">Les places étant limitées, l’inscription est obligatoire</p>
        <div class="mobile button">
            <Button title={buttonTitle} link={buttonLink}/>
        </div>
        <img class="grapik-3 fullsize" src="assets/grapik_3.svg" alt="forme gemetrique"/>
        <img class="grapik-4 mobile" src="assets/grapik_4.svg" alt="forme gemetrique"/>
        <img class="grapik-2" src="assets/grapik_2.svg" alt="forme gemetrique"/>
    </div>
</div>