<style lang="scss">
    .wrapper {
        display: flex;
        margin-bottom: 12vw;
        @media (max-width: 768px) {
            flex-direction: column-reverse;
            align-items: center;
            margin-bottom: 23vw;
        }
    }
    
    .date {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
        font-size: 4.69vw;
        border-width:0 13px 0 0;
        border-style: solid;
        border-image: linear-gradient(to bottom,  #EE2238,  #BF1D67 ) 1 100%;
        border-image-slice: 1;
        text-align: right;
        padding-right: 2.9vw;
        letter-spacing: 0.6vw;
        display: flex;
        flex-direction: column;
        justify-content: center;

        & > p:first-child{
            font-weight: 200;
        }
        
        & > p:last-child{
            font-weight: bold;
        }

        @media (max-width: 768px) {
            border-width:4px 0 0 0;
            width: 48.5vw;
            text-align: center;
            padding:3.8vw 0 0 0;
            font-size: 9.3vw;
            line-height: 13.4vw;
            letter-spacing: 1.15vw;
            margin-right: -1.15vw;
        }
    }

    .title {
        flex-basis: 0;
        flex-shrink: 0;
        flex-grow: 2;
        padding-left: 4.16vw;
        font-weight: 100;
        
        & > p:first-child {
            font-size: 2.1vw;
            line-height: 2.55vw;
        }
    
        & > p:nth-child(2) {
            font-size: 4.16vw;
            line-height: 4.16vw;
            letter-spacing: 1.6px;
            font-weight: bold;    
        }
    
        & > p:nth-child(3) {
            font-size: 1.66vw;
            line-height: 2vw;
        }

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;            
            padding-left: 0;
            text-align:center;
            align-items: center;

            & >p {
                margin-bottom: 8vw;
            }
            & > p:first-child {
            font-size: 4.5vw;
            line-height: 5.6vw;
        }
    
        & > p:nth-child(2) {
            font-size: 6.34vw;
            line-height: 7.7vw;
            letter-spacing: 1.3px;

        }
    
        & > p:nth-child(3) {
            font-size: 4.76vw;
            line-height: 5.6vw;
            width: 83vw;
        }
    }
}
</style>

<div class="wrapper">    
    <div class="date">
        <p>27/01</p>
        <p>2022</p>
    </div >
    <div class='title'>
        <p>Une conférence mixant</p>
        <p>Product Managment & Craftmanship</p>
        <p>« comment faire pour concevoir le bon produit et bien le réaliser ? »</p>
    </div>
</div>
