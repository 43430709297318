<script>
    import Title from "./Title.svelte";
    import Button from "./Button.svelte"; 
    
    const buttonTitle = "Next 2020"
    const buttonLink ="https://next-conf.com/"
</script>

<style lang="scss">
    
    .fullsize {
        @media (max-width: 768px) {
            display: none;
        }
    }
    .mobile {
        display: none;
        @media (max-width: 768px) {
            display: block;
        }
    }
    .wrapper {
        margin-bottom: 11.7vw;
        position: relative;
        @media (max-width: 768px) {
            margin-bottom: 15vw;
        }
    }

    .content {
        display: flex;
        margin-top: 2.5vw;
        align-items: center;
        overflow-x: clip;
        @media (max-width: 768px) {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
    }
    
    .video {
        width: 60%;
        &  > img{
            width: 52.9vw;
            height: 29.7vw;
            object-fit: cover;
        }
        
        @media (max-width: 768px) {
            width: 100%;
            margin-top: 5.5vw;
            display: flex;
            flex-direction: column;
        }
    }


    .description {
        width: 40%;
        font-size: 2.2vw;
        line-height: 2.6vw;
        @media (max-width: 768px) {
            width: 80%;
            margin-left: 10.9vw;
            font-size: 4.5vw;
            line-height: 5.4vw;
        }
    }
    .description > p {
        margin-bottom: 2.6vw;
    }

    .description span {
        font-weight: bold;
    }

    .shape {
        z-index:-1;
        position: absolute;
        top: 9.7vw;
        left: -0.6vw;
        height: 43.5vw;
        @media (max-width: 768px) {
            top: 61vw;
            height: 47vw; 
        }
    }

    .details {
        @media (max-width: 768px) {
            align-self: flex-end;
            width: 40%;
            font-weight: 500;
            font-size: 3.2vw;
            > p {
                margin-top: -10vw;
                margin-bottom: 4vw;
            }
        }
    }
    
    .accent {
        position: absolute;
        left: 5.6vw;
        height: 58vw;
        width: 0.7vw;
        background: transparent linear-gradient(180deg, #EE2238 0%, #BF1D67 100%) 0% 0% no-repeat padding-box;
    }
</style>

<div class="wrapper">    
    <div class="mobile accent"/>
    <Title title="Édition précédente" subTitle="La transformation de l’entreprise par le design"/>
    <div class="content">
        <div class="video">
            <img src="assets/video-placeholder.png" alt="video placeholder"/>
            <div class="mobile details">
                <p ><span>Envie d’en savoir plus sur cette édition</span></p>
                <Button title={buttonTitle} link={buttonLink}/>
            </div>
        </div>
        <div class="description">
            <p>En 2020, la <span>NEXT</span> , qui avait pour thématique le design, avait regroupé plus de 200 participants et une quinzaine de speakers.</p>
            <p class="fullsize"><span>Envie d’en savoir plus sur cette édition</span></p>
            <div  class="fullsize">
                <Button title={buttonTitle} link={buttonLink}/>
            </div>
        </div>
    </div>
    <img class="shape" src='assets/grapik_5.svg' alt="forme géométrique"/>
</div>
